import React from "react";
import { Helmet } from "react-helmet";

import Layout from "../components/Layout";
import Home from "../components/Home/Home";

import Favicon from "../images/Favicon.png";

import ogImage from "../images/Metadata/home.jpg"

const IndexPage = () => {
  return (
      <>
          <Helmet>
                <meta charSet="utf-8"/>
                <title>Drivosity</title>
                <link rel="icon" type="image/png" sizes="32x32" href={Favicon}/>
                <meta name="keywords" content="last-mile, food, restaurant"/>
                <meta name="description"
                    content="Discover how Drivosity can transform your delivery operations with innovative GPS tracking and driver management tools. Increase safety, reduce costs, and enhance customer experience. Request a demo today!"/>

                <meta property="og:title" content="Drivosity"/>
                <meta property="og:description" content="Discover how Drivosity can transform your delivery operations with innovative GPS tracking and driver management tools. Increase safety, reduce costs, and enhance customer experience. Request a demo today!"/>
                <meta property="og:image" content={`${process.env.GATSBY_SITE_URL}${ogImage}`}/>
                <meta property="og:image:alt" content="Aerial view of a suburban neighborhood with houses, trees, and roads, representing real estate, community living, or home services."/>
                <meta property="og:type" content="website"/>
                <meta property="og:url" content={`${process.env.GATSBY_SITE_URL}`}/>
          </Helmet>
          <Layout component={<Home/>}/>
      </>
  )
}

export default IndexPage;
