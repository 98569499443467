import * as React from "react";

import DrvVerticalTaber from "../Shared/DrvVerticalTaber/DrvVerticalTaber";
import DrvContent from "../Shared/DrvContent/DrvContent";
import DrvSlider from "../Shared/DrvSlider/DrvSlider";
import DrvSubFooter from "../Shared/DrvSubFooter/DrvSubFooter";
import DrvStoryComment from "../Shared/DrvSlider/DrvStoryComment/DrvStoryComment";
import DrvBrand from "../Shared/DrvSlider/DrvBrand/DrvBrand";
import {DrvButton} from "../Shared/DrvButton/DrvButton";
import Counter from "./Counter/Counter";
import IntroVideo from "./IntroVideo/IntroVideo";
import {AiOutlineArrowRight} from "react-icons/ai";
import {
    CommentContainer,
    SliderContent,
    SliderHeader
} from "./Home.styles";
import {HomeData, HomeSliderData, HomeCommentsData, HomeNewsData} from "./Home.data";

import First from "../../images/Home/First.jpg";
import Second from "../../images/Home/Second.jpg";
import PapaJohns from "../../images/Home/PapaJohns.png";
import Dominos from "../../images/Home/Dominos.png";
import MarcosPizza from "../../images/Home/MarcosPizza.png";
import JimmyJohns from "../../images/Home/JimmyJohns.png";
import PizzaHut from "../../images/Home/PizzaHut.png";
import ToppersPizza from "../../images/Home/ToppersPizza.png";
import JetsPizza from "../../images/Home/JetsPizza.png";
import Hth from "../../images/Home/Hth.png";
import GeekSquad from "../../images/Home/GeekSquad.png";
import BestBuy from "../../images/Home/BestBuy.png";
import Intrepid from "../../images/Home/Intrepid.png";
import DrvCardNews from "../Shared/DrvCardNews/DrvCardNews";

const customers = [PapaJohns, Dominos, MarcosPizza, JimmyJohns, PizzaHut, ToppersPizza, JetsPizza];
const partners = [Hth, GeekSquad, BestBuy, Intrepid];

const Home = () => {
    return (
        <>
            {/*CONTENT*/}
            <IntroVideo />

            {/*CONTENT*/}
            <div className="py-14">
                <DrvContent
                    type={['text', 'image']}
                    content={[
                        (
                            <>
                                <div className='drv-subtitle'>
                                    {HomeData[0].title}
                                </div>
                                <div className='drv-description'>
                                    {HomeData[0].content}
                                </div>
                            </>
                        ),
                        (
                            <img src={First} alt="Drivosity application mockup" />
                        )
                    ]}
                />
            </div>

            {/*CONTENT*/}
            <div className="py-14">
                <DrvContent
                    type={['image']}
                    content={[
                        (
                            <img src={Second} alt="Drivers in delivery" />
                        )
                    ]}
                />
            </div>

            {/*CONTENT*/}
            <div className="py-14" style={{backgroundColor: '#f4f4f4'}}>
                <DrvVerticalTaber data={HomeSliderData} />
            </div>

            {/*CONTENT*/}
            <div className='flex items-center' style={{minHeight: '150px', backgroundColor: 'var(--primary-color)'}}>
               <Counter />
            </div>

            {/*CONTENT*/}
            <CommentContainer>
                <SliderHeader>{HomeData[3].title}</SliderHeader>
                <DrvSlider slides={HomeCommentsData}
                           slide={DrvStoryComment}
                           sliderClass='story-comment-slider'
                           slideClass='story-comment-slide'
                           slidesPerView='auto'
                           effect='coverflow'
                           center={true}
                           pagination={true}
                />
            </CommentContainer>

            {/*CONTENT*/}
            <SliderContent>
                <SliderHeader>FEATURED CUSTOMERS</SliderHeader>
                <DrvSlider slides={customers}
                           slide={DrvBrand}
                           sliderClass='customer-slider'
                           slideClass='customer-slide'
                           breakpoints={{
                               230: {
                                   slidesPerView: 1
                               },
                               460: {
                                   slidesPerView: 2
                               },
                               690: {
                                   slidesPerView: 3
                               },
                               920: {
                                   slidesPerView: 4
                               },
                               1150: {
                                   slidesPerView: 5
                               }
                           }}
                />
            </SliderContent>

            {/*CONTENT*/}
            <SliderContent>
                <SliderHeader>FEATURED PARTNERS</SliderHeader>
                <DrvSlider slides={partners}
                           slide={DrvBrand}
                           sliderClass='partner-slider'
                           slideClass='partner-slide'
                           breakpoints={{
                               380: {
                                   slidesPerView: 1
                               },
                               760: {
                                   slidesPerView: 2
                               },
                               1140: {
                                   slidesPerView: 3
                               },
                               1500: {
                                   slidesPerView: 4
                               },
                           }}
                />
            </SliderContent>


            {/*CONTENT*/}
            <div className="py-14" style={{background: "#f5f5f7"}}>
                <DrvCardNews
                    type={['text']}
                    title={"Stay Informed With Drivosity"}
                    description={"Stay ahead in the fast-paced world of first-party delivery! Drivosity is proud to be featured in Forbes, where we share insights on driver safety, delivery efficiency, and industry trends."}
                    data={HomeNewsData}
                />
            </div>
            {/*CONTENT*/}
            <DrvSubFooter
                color="#f5f5f7"
                background="var(--primary-color)"
                description={HomeData[1].content}
                button={
                    <DrvButton to="/contact-us" background="var(--primary-color)" bordercolor="white" className="w-fit">
                        CONTACT US
                        <AiOutlineArrowRight style={{fontSize: "17px"}} className="ml-3" />
                    </DrvButton>
                }
            />
        </>
    )
}

export default Home;
