import * as React from "react";

import ToTheDoor  from '../../images/Home/TaberTTD.jpg';
import Replay from '../../images/Home/TaberReplay.jpg';
import Certified from '../../images/Home/TaberCertified.jpg';
import Trips from '../../images/Home/TaberTrip.jpg';

import News28 from '../../images/OurCompany/News/news28.png';
import News31 from '../../images/OurCompany/News/news31.png';
import News32 from '../../images/OurCompany/News/news32.png';

const HomeData = [
    {
        id: 0,
        title: `Unlock the Power of DriveScore®`,
        content: (
            <>
                At the heart of Drivosity is our patented DriveScore® system, a revolutionary approach to driver performance that combines gamification with real-time analytics. This unique scoring algorithm measures key driving behaviors—like speeding, hard braking, acceleration, and hard cornering—empowering drivers and managers to foster a culture of safety and accountability. 
                <br/><br/>
                <span className='strong'>Why Drivosity?</span>
                <br/><br/>
                Imagine a delivery experience where every driver is motivated to excel, and every delivery is a success story. With Drivosity, you can achieve just that.
            </>
        ),
        location: `/`,
        data: null,
    },
    {
        id: 0,
        title: `Ready to Revolutionize Your Deliveries?`,
        content: (
            <div className="text-[13px]">
                    <h1 className="text-[20px]">Ready to Revolutionize Your Deliveries?</h1>
                    <br/><br/>
                    Don’t just keep up with the competition—lead the way. With Drivosity, you’re not just optimizing deliveries; you’re creating a culture of excellence.
                    <br/><br/>
                    Contact us today for a personalized demo and discover how Drivosity can elevate your delivery operations to new heights. 
                    <br/><br/>
                    Let’s drive the future of delivery—together.
                </div>
        ),
        location: `/`,
        data: null,
    },
    {
        id: 0,
        title: ``,
        content: `
            Transform Your Delivery Operations,
            Drivers,
            Drivosity Trips,
            Miles Driven,
            Miles Driven Weekly,
            FEATURED CUSTOMERS,
            FEATURED PARTNERS
        `,
        location: `/`,
        data: null,
    },
    {
        id: 4,
        title: `Real Results, Real Stories`,
        content: '',
        location: `/`,
        data: {
            image: ToTheDoor
        }
    },
];

const HomeSliderData = [
    {
        id: 0,
        title: `Gamified Safety and Performance`,
        content:(
            <>
                Our DriveScore® system turns safe driving into a game. Drivers receive scores based on their performance, encouraging them to improve and compete for recognition. 
                This not only inspires safer driving habits but also <span className='strong'>reduces accidents by up to 90%</span> 
            </>
        ),
        location: `/`,
        data: {
            image: Trips
        }
    },
    {
        id: 1,
        title: `Efficiency at Every Turn`,
        content: 'Optimize your delivery routes with real-time navigation and tracking that cuts extended stops by an average of 18%. More deliveries mean happier customers and increased revenue for your business. ',
        location: `/`,
        data: {
            image: Replay
        }
    },
    {
        id: 2,
        title: `Insights That Drive Growth`,
        content: 'Leverage comprehensive analytics to uncover opportunities for improvement. Our platform provides actionable insights that help you replicate the success of your top drivers across your entire fleet.',
        location: `/`,
        data: {
            image: Certified
        }
    },
    {
        id: 3,
        title: `The Drivosity Difference`,
        content: (
            <>
                <ul class="list-disc list-inside text-[14px]">
                    <li> 
                        <span className='strong'>Happier Drivers: </span> 
                         With transparent performance metrics and gamified shifts, your drivers will feel valued and motivated, leading to lower turnover rates.
                    </li>
                    <li> 
                        <span className='strong'>Delighted Customers: </span>
                         Faster, safer deliveries translate to five-star reviews and increased customer loyalty. </li>
                    <li>
                        <span className='strong'>Healthier Bottom Line: </span> 
                         Reduce operational costs and increase profitability by minimizing accidents and optimizing delivery efficiency. Our clients report <span className='strong'>savings up to 75% on insurance costs.</span>
                    </li>
                </ul>
            </>
        ),
        location: `/`,
        data: {
            image: ToTheDoor
        }
    }
];

const HomeCommentsData = [
    {
        id: 0,
        title: ``,
        content: 'Drivosity is a game changer when it comes to safety awareness and accountability to safe driving. Since installing Drivosity, I have had way less incidents regarding driver safety because I am aware of driving habits and can address issues immediately. This system makes our company a better place to work, while providing data to increase productivity. Insurance costs have also decreased by more than 30% after only 2 years with Drivosity.',
        data: {
            name: 'Charles Peters',
            position: 'Operating Partner'
        },
        location: `/`
    },
    {
        id: 0,
        title: ``,
        content: 'I’m always searching for the best technologies that make a big impact. Drivosity has certainly exceeded our expectations. Their customer service is outstanding. Always a quick response. Drivosity has also had a huge positive effect on our businesses; from decreasing service times and increasing driver safety to improving delivery times. I recommend Drivosity to everyone in the industry!',
        data: {
            name: 'Sammy Mandell',
            position: 'Restaurant Owner/Entrepreneur'
        },
        location: `/`
    },
    {
        id: 0,
        title: ``,
        content: 'Drivosity’s mission is to save lives by creating better drivers.  They do that.  They also provide me with easy-to-read, transparent metrics that allow me to see in real time how my drivers are performing and how well they are abiding by our safety standards.  We expected this when we signed up for Drivosity.  What we did not expect was that their services would increase our productivity, optimize our workflow, and elevate our customer satisfaction scores.  I have been in this industry for almost twenty years and I can count on one hand the number of innovations that have fundamentally transformed our business.  Drivosity is one of those innovations.',
        data: {
            name: 'Brian Krasielwicz',
            position: 'Director of Operations'
        },
        location: `/`
    },
    {
        id: 0,
        title: ``,
        content: 'I can’t imagine being in a delivery business without Drivosity. With Drivosity, we are able to dispatch deliveries efficiently while focusing on our customers as a top priority. Safe driving is measured along with actual drive times, creating a positive behavior among delivery drivers. Love It!',
        data: {
            name: 'Wendy Longo',
            position: 'General Manager'
        },
        location: `/`
    },
];
const HomeNewsData = [
    {
        id: 0,
        description: "Integrating advanced POS systems streamlines restaurant operations, offering real-time insights, improved customer experiences, and efficient workflows.",
        externalLink: 'https://www.forbes.com/councils/forbesbusinesscouncil/2024/12/18/harnessing-pos-for-effective-labor-management-in-the-food-industry/',
        image: News32,
        alt: "Waiter serving food to customers during party in restaurant",
    },
    {
        id: 1,
        description: "Steering Toward Savings: Addressing The Hired And Non-Owned Auto Insurance Challenge In Food Delivery",
        externalLink: 'https://www.forbes.com/councils/forbesbusinesscouncil/2024/09/26/steering-toward-savings-addressing-the-hired-and-non-owned-auto-insurance-challenge-in-food-delivery/',
        image: News31,
        alt: "Happy delivery driver hands over a package, representing the strategic balance between first-party and third-party food delivery methods.",
    },
    {
        id: 2,
        description: "Mastering The Balancing Act Between First-Party and Third-Party Food Delivery",
        externalLink: 'https://www.forbes.com/councils/forbesbusinesscouncil/2024/07/23/mastering-the-balancing-act-between-first-party-and-third-party-food-delivery/',
        image: News28,
        alt: "Happy delivery driver hands over a package, representing the strategic balance between first-party and third-party food delivery methods.",
    },
]

export {HomeData, HomeSliderData, HomeCommentsData,HomeNewsData};
